import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Form, Field } from 'react-final-form';
import { FadeAnimationNoStyles } from '../../common/Styles';
const sgMail = require('@sendgrid/mail');

const Container = styled.div`
  display: flex;
  width: 90%;
  margin: 20px auto;
  margin-bottom: 50px;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
  }
`;

const MyInfo = styled.div`
  margin: 50px auto;
  font-size: 18px;
  text-align: center;
`;

const btn = (light, dark) => css`
  white-space: nowrap;
  display: inline-block;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 16px;
  color: white;
  &:visited {
    color: white;
  }
  background-image: linear-gradient(${light}, ${dark});
  border: 1px solid ${dark};
  &:hover {
    background-image: linear-gradient(${light}, ${dark});
    &[disabled] {
      background-image: linear-gradient(${light}, ${dark});
    }
  }
  &:visited {
    color: black;
  }
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const btnDefault = css`
  ${btn('#ffffff', '#d5d5d5')} color: #555;
`;

const btnPrimary = btn('#4f93ce', '#285f8f');

const Styled = styled.div`
  width: 100%;

  h1 {
    text-align: center;
    color: #222;
  }

  & > div {
    text-align: center;
  }

  a {
    display: block;
    text-align: center;
    color: #222;
  }

  form {
    max-width: 500px;
    margin: 0px auto;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 3px;

    @media (max-width: 400px) {
      padding: 5px;
    }

    & > div {
      display: flex;
      flex-flow: row nowrap;
      line-height: 2em;
      margin: 5px;
      & > label {
        color: #333;
        width: 110px;
        font-size: 1em;
        line-height: 32px;
      }
      & > input,
      & > select,
      & > textarea {
        flex: 1;
        padding: 3px 5px;
        font-size: 1em;
        border: 1px solid #ccc;
        border-radius: 3px;
      }
      & > input[type='checkbox'] {
        margin-top: 7px;
      }
      & > div {
        margin-left: 10px;
        & > label {
          display: block;
          & > input {
            margin-right: 3px;
          }
        }
      }
    }
    & > .buttons {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      margin-top: 15px;
    }
    button {
      margin: 0 10px;
      &[type='submit'] {
        ${btnPrimary};
      }
      &[type='button'] {
        ${btnDefault};
      }
    }
    pre {
      border: 1px solid #ccc;
      background: rgba(0, 0, 0, 0.1);
      box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
      padding: 20px;
    }
  }
`;

class index extends Component {
  sendEmail = async (values, form) => {
    this.setState({ loading: true });
    form.reset();

    sgMail.setApiKey(process.env.REACT_APP_SENDGRID_API_KEY);
    const msg = {
      to: 'chrisanderson@myself.com',
      from: values.email + '',
      subject: values.subject + '',
      text: 'hello',
      html:
        `<h4>New message from ${values.firstName} ${values.lastName}</h4><p>${values.message}</p>` +
        '',
    };

    // const msg = {
    //   to: 'chrisanderson@myself.com',
    //   from: values.email + '',
    //   subject: 'Sending with SendGrid is Fun',
    //   text: 'and easy to do anywhere, even with Node.js',
    //   html: '<strong>and easy to do anywhere, even with Node.js</strong>',
    // };
    sgMail.send(msg).catch((err) => console.log('error!'));
    this.setState({ loading: false, emailSent: true });
  };
  render() {
    return (
      <FadeAnimationNoStyles>
        <Container>
          {/* <Styled> */}
          {/* <h1>Contact</h1> */}
          {/* <Form
              onSubmit={this.sendEmail}
              initialValues={{}}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <label>First Name</label>
                    <Field
                      name="firstName"
                      component="input"
                      type="text"
                      placeholder="First Name"
                    />
                  </div>
                  <div>
                    <label>Last Name</label>
                    <Field
                      name="lastName"
                      component="input"
                      type="text"
                      placeholder="Last Name"
                    />
                  </div>
                  <div>
                    <label>Email</label>
                    <Field
                      name="email"
                      component="input"
                      type="email"
                      placeholder="Your email address"
                    />
                  </div>
                  <div>
                    <label>Subject</label>
                    <Field
                      name="subject"
                      component="input"
                      placeholder="Subject"
                    />
                  </div>
                  <div>
                    <label>Message</label>
                    <Field
                      name="message"
                      component="textarea"
                      placeholder="What do you want to say?"
                      style={{ height: '300px' }}
                    />
                  </div>
                  <div className="buttons">
                    <button
                      type="submit"
                      onClick={() => this.sendEmail(values, form)}
                      disabled={submitting || pristine}
                    >
                      Send
                    </button>
                    <button
                      type="button"
                      onClick={form.reset}
                      disabled={submitting || pristine}
                    >
                      Reset
                    </button>
                  </div>
                </form>
              )}
            /> */}
          {/* </Styled> */}
          <MyInfo>
            {/* <h3>
              <i>My Contact Information</i>
            </h3> */}
            <br />
            <h5>Angela Barentine </h5>
            (229) 624-2413 <br />
            <a
              href="mailto:barentinephotography@gmail.com?Subject=BarentinePhotography"
              target="_"
            >
              barentinephotography@gmail.com
            </a>
          </MyInfo>
        </Container>
      </FadeAnimationNoStyles>
    );
  }
}

export default index;
