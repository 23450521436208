import {
  SET_GALLERY_INDEX,
  SET_GALLERIES,
} from '../components/GalleriesHome/constants';

export default (state = { selectedIndex: null, galleries: [] }, action) => {
  switch (action.type) {
    case SET_GALLERY_INDEX:
      return {
        ...state,
        selectedIndex: action.payload,
      };
    case SET_GALLERIES:
      return {
        ...state,
        galleries: action.payload,
      };
    default:
      return state;
  }
};
