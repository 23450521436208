import React from "react";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import { carousel_photos } from "./carousel_photos";

const Image = styled.img`
  object-fit: cover;
  height: 200px;

  @media (min-width: 500px) {
    /* max-width: 95%; */
    height: 300px;
  }
  @media (min-width: 1000px) {
    /* max-width: 95%; */
    height: 400px;
  }
  @media (min-width: 1300px) {
    /* max-width: 95%; */
    height: 500px;
  }
  @media (min-width: 1500px) {
    /* max-width: 95%; */
    height: 550px;
  }
`;

const CarouselV2 = () => {
  const images = carousel_photos;
  const items = images.map((src, index) => {
    return (
      <div>
        <Image src={src} alt={"Image " + index} />
      </div>
    );
  });
  return (
    <Carousel dynamicHeight autoPlay>
      {items}
    </Carousel>
  );
};

export default CarouselV2;
