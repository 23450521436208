import { SET_GALLERY_INDEX, SET_GALLERIES } from './constants';

export const setGalleryIndex = (index) => (dispatch) => {
  dispatch({
    type: SET_GALLERY_INDEX,
    payload: index,
  });
};
export const setGalleries = (galleries) => (dispatch) => {
  dispatch({
    type: SET_GALLERIES,
    payload: galleries,
  });
};
