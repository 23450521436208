import React, { useState } from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-bootstrap';
import { carousel_photos } from './carousel_photos';

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1300px;
  margin: 0px auto;
  max-height: 600px;
  min-height: 300px;
  height: auto;

  .slide {
    width: 100%;
  }
`;

const Image = styled.img`
  max-width: 100%;
  min-height: 300px;
  max-height: 600px;
  width: 100%;
  object-fit: cover;
  /* object-position: bottom; */
`;
const ImageContainer = styled.div`
  max-height: 600px;
  min-height: 300px;
  height: 100%;
`;

const CarouselItem = styled(Carousel.Item)`
  height: 600px;
  width: 100%;

  @media (max-width: 700px) {
    height: 300px;
  }
`;

function ControlledCarousel() {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setDirection(e.direction);
  };
  // const images = [
  //   'https://images.squarespace-cdn.com/content/v1/5b9be065e2ccd1cd7c8205f1/1537546496761-9LIXQO0QZQ7JWR3ED56R/ke17ZwdGBToddI8pDm48kARt5Kw0opPLd1kSsuXYvmx7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UQZkGsZzDarfz0r77g-0cdEvHWsc9M2ZjGTBL-NXyeu_PWBPzGMSuNjazfGjdWUTSw/SAM7.jpg?format=1500w',
  //   'https://images.squarespace-cdn.com/content/v1/5b9be065e2ccd1cd7c8205f1/1537546510321-Y4IRS9HPTF9LS164AKNQ/ke17ZwdGBToddI8pDm48kESCedg6XIdx6EAwq3JF0AF7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UZQvcmxFrjqJcQiVnGSJIIkoctlP2JCDARi8H3u4bbWTz5iHq4kLwGJixtaXMnHR7A/SAM8.jpg?format=1500w',
  //   'https://images.squarespace-cdn.com/content/v1/5b9be065e2ccd1cd7c8205f1/1537545834669-QMCBVG7MD352P6J5O1PX/ke17ZwdGBToddI8pDm48kKtijf5x5S0rIV7X_qDH3dB7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UaZbTVdO5VSPAOxIcVIbmIFLIFeVDbQiz7iBIgNCzklBDD2o6CESiqIlH5ssNFrtmA/Tara7.jpg?format=2500w',
  //   'https://www.simplyrecipes.com/wp-content/uploads/2015/01/perfect-popcorn-vertical-b-1800.jpg',
  // ];

  const images = carousel_photos;
  const items = images.map((src, index) => {
    return (
      <CarouselItem>
        <ImageContainer>
          <Image className="d-block w-100" src={src} alt={'Image ' + index} />
        </ImageContainer>
      </CarouselItem>
    );
  });

  return (
    <Container>
      <Carousel
        activeIndex={index}
        direction={direction}
        onSelect={handleSelect}
        interval={3000}
      >
        {items}
      </Carousel>
    </Container>
  );
}

export default ControlledCarousel;
