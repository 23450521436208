import React, { Component } from 'react';
import Logo from '../Logo';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setGalleryIndex } from '../GalleriesHome/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faInstagram,
  faPinterestP,
} from '@fortawesome/free-brands-svg-icons';

const mobileBreakpoint = '800px';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 20%; */
  padding: 2% 20px;
`;

const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  height: 100%;
`;

const TopRightSection = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: center;
  align-items: flex-end;
  font-style: italic;
  font-family: 'Red Hat Display', sans-serif;
  text-transform: uppercase;
  flex-wrap: wrap;
  @media (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;
const HorizontalLine = styled.hr`
  width: 100%;
  color: grey;
  @media (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;
const BottomRightSection = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  text-transform: uppercase;
  font-family: 'Red Hat Display', sans-serif;
  @media (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;
const TopLeftSection = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: center;
  align-items: flex-end;
  font-style: italic;
  font-family: 'Red Hat Display', sans-serif;
  text-transform: lowercase;
  /* font-weight: bold; */

  flex-wrap: wrap;
  @media (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;
const BottomLeftSection = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  text-transform: uppercase;
  font-family: 'Red Hat Display', sans-serif;
  @media (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;

const FlexItem = styled.div`
  text-align: center;
  margin: 0px 10px;
  text-decoration: none;
`;
const StyledLink = styled(Link)`
  display: flex;
  transition: color 0.5s;
  color: #222;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;

  &:hover {
    color: #767676;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 80%;
`;

const navBarElements = [
  {
    route: '/about',
    title: 'About',
  },
  {
    route: '/pricing',
    title: 'Pricing',
  },
  {
    route: '/contact',
    title: 'Contact',
  },
];
let NavElements = navBarElements.map((element) => (
  <FlexItem>
    <StyledLink to={element.route}>{element.title}</StyledLink>
  </FlexItem>
));

class index extends Component {
  // <Container>

  // {NavElements}
  // </Container>

  render() {
    return (
      <Container>
        <SideContainer>
          <TopLeftSection>{'Family, Individual, and Event Photography'}</TopLeftSection>
          <HorizontalLine />
          <BottomLeftSection>
            <FlexItem>
              <StyledLink to="/">{'Home'}</StyledLink>
            </FlexItem>
            <FlexItem>
              <StyledLink
                to={{
                  pathname: '/galleries',
                  state: { selectedCategoryIndex: null },
                }}
                onClick={() => this.props.setGalleryIndex(null)}
              >
                {'Galleries'}
              </StyledLink>
            </FlexItem>
            <FlexItem>
              <StyledLink to="/weddings">{'Weddings'}</StyledLink>
            </FlexItem>
          </BottomLeftSection>
        </SideContainer>
        <Logo></Logo>
        <SideContainer>
          <TopRightSection>
            <IconWrapper>
              <StyledLink>
                <FontAwesomeIcon icon={faEnvelope} size="lg" />
              </StyledLink>
              <StyledLink>
                <FontAwesomeIcon icon={faFacebookSquare} size="lg" />
              </StyledLink>
              <StyledLink>
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </StyledLink>
              <StyledLink>
                <FontAwesomeIcon icon={faPinterestP} size="lg" />
              </StyledLink>
            </IconWrapper>
          </TopRightSection>
          <HorizontalLine />

          <BottomRightSection>{NavElements}</BottomRightSection>
        </SideContainer>
        {/* <FontAwesomeIcon icon={faBootstrap} size="lg" /> */}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setGalleryIndex: (index) => dispatch(setGalleryIndex(index)),
});

export default connect(
  null,
  mapDispatchToProps,
)(index);
