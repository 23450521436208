import React, { Component } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from '../Home';
import GalleriesHome from '../GalleriesHome';
import Weddings from '../Weddings';
import PageHeader from '../PageHeader';
import AboutPage from '../AboutPage';
import PackagesPage from '../PackagesPage';
import ContactPage from '../ContactPage';
import AdminViewController from '../Admin/AdminViewController';

import { connect } from 'react-redux';
import { setGalleryIndex } from '../GalleriesHome/actions';
import HamburgerIcon from '../HamburgerIcon';
import { selectGalleries } from '../GalleriesHome/selectors';

const Wrapper = styled.div`
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
`;

const TopPadding = styled.div``;

class index extends Component {
  render() {
    return (
      <Router>
        <Wrapper>
          <HamburgerIcon />
          <TopPadding />
          <PageHeader />
          <Route exact path="/" component={Home} />
          <Route path="/about" component={AboutPage} />
          <Route path="/galleries" component={GalleriesHome} />
          <Route path="/weddings" component={Weddings} />
          <Route path="/pricing" component={PackagesPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/admin" component={AdminViewController} />
        </Wrapper>
      </Router>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setGalleryIndex: (index) => dispatch(setGalleryIndex(index)),
});

const mapStateToProps = (state) => ({
  selectGalleries: selectGalleries(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(index);
