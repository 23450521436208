import React from 'react';
import withFirebaseAuth from 'react-with-firebase-auth';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import 'firebase/auth';
import 'firebase/firestore';
import * as firebase from 'firebase/app';
import 'bootstrap/dist/css/bootstrap.min.css';
import { firebaseConfig } from './firebase.config';

firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <div className="App">
      <Router>
        <LandingPage />
      </Router>
    </div>
  );
}

export default connect()(App);
