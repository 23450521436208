import React, { Component } from "react";
import styled from "styled-components";
import ControlledCarousel from "../ControlledCarousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getPlaceholderImages } from "../../utils/api_helper";
import { FadeAnimationNoStyles } from "../../common/Styles";
import CarouselV2 from "../ControlledCarousel/CarouselV2";

const mobileBreakPoint = "700px";

const CarouselWrapper = styled.div`
  display: block;
  margin: 0px auto;
  max-width: 95%;

  @media (min-width: ${mobileBreakPoint}) {
    max-width: 70%;
  }

  @media (min-width: 1500px) {
    max-width: 60%;
  }
`;

const HorizontalLine = styled.hr`
  width: 60%;
  margin: 30px auto;
`;

class Home extends Component {
  state = {
    url: null,
    photos: [],
  };

  async componentDidMount() {
    let photos = await getPlaceholderImages(20, "hello world");
    this.setState({ photos });
  }

  render() {
    return (
      <FadeAnimationNoStyles>
        <CarouselWrapper>
          {/* <Carousel /> */}
          {/* <ControlledCarousel /> */}
          <CarouselV2 />
        </CarouselWrapper>
        <HorizontalLine />

        {/* <GalleryWrapper> */}
        {/* Some info or a few pictures could go here... */}
        {/* <br />
          <br />
          <br /> */}
        {/* <ImageGallery photos={stockNaturePhotos} /> */}
        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.{' '} */}
        {/* </GalleryWrapper> */}
      </FadeAnimationNoStyles>
    );
  }
}

export default Home;
