import React from 'react';
import styled from 'styled-components';
import LogoImage from '../../resources/Logo.jpg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  height: 100%;

  @media (max-width: 800px) {
    border: none;
  }
`;

const Logo = styled.img`
  object-fit: cover;
  opacity: 1;
  display: block;
  /* height: 70%; */
  width: 70%;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 10px;
  @media (max-width: 800px) {
    width: 100%;
    height: unset;
    padding: 10px;
  }

  @media (max-width: 700px) {
    padding: 0px;
  }
  @media (max-width: 600px) {
    width: 115%;
  }
`;

const index = (props) => {
  return (
    <Container>
      <Logo src={LogoImage} alt="logo" />
    </Container>
  );
};

export default index;
