import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Modal, Button } from "react-bootstrap";
import * as firebase from "firebase/app";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

const LoginButton = styled(Button)`
  width: 100%;
  background-color: #363740 !important;
  outline: #363740 !important;
  border: none !important;

  &:hover {
    background-color: #4f515e !important;
  }
`;

const PasswordError = styled.div`
  font-size: 14px;
  color: red;
  margin-bottom: 14px;
`;

const PasswordPrompt = (props) => {
  const [passwordVal, setPasswordVal] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  // In real/important scenario, this would be some serverside function. Easily hackable like this
  const validatePassword = () => {
    const db = firebase.firestore();

    db.collection("Galleries")
      .doc(props.galleryName)
      .get()
      .then(function (querySnapshot) {
        console.log("insideValidatePass");
        console.log(querySnapshot.data());
        const thisGalleriesPassword = querySnapshot.data().password;
        if (passwordVal === thisGalleriesPassword) {
          props.fetchWeddingPhotos();
        } else {
          setPasswordError(true);
        }
      });
  };

  return (
    <Modal {...props} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title size={12} id="contained-modal-title-vcenter">
          Login to the Gallery
        </Modal.Title>
      </Modal.Header>
      <Modal.Body centered>
        {/* <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p> */}

        {/* TODO: Why refresh on enter? */}
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validatePassword();
          }}
        >
          {/* TODO: FIX setting to false */}
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => setPasswordVal(e.target.value)}
              value={passwordVal}
              onKeyDown={(e) => {
                // e.preventDefault();
                setPasswordVal(e.target.value);
                if (e.keyCode === 13) {
                  validatePassword();
                }
              }}
            />
          </Form.Group>
          {/* <Form.Label>Password</Form.Label> */}
          {passwordError && <PasswordError>Incorrect Password</PasswordError>}
          <LoginButton onClick={() => validatePassword()}>Login</LoginButton>
        </Form>
      </Modal.Body>

      {/* <Modal.Footer>
        <Button
          onClick={() => {
            props.onHide();
            props.onSuccess();
          }}
        >
          Submit
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default PasswordPrompt;
