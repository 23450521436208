/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import styled from 'styled-components';
import ImageGallery from '../ImageGallery';
import { FadeAnimationNoStyles } from '../../common/Styles';

const GalleryWrapper = styled.div`
  margin: 0px auto;
  margin-bottom: 6%;
  width: 85%;
  display: flex;
  justify-content: center;
`;

class index extends Component {
  render() {
    return (
      <FadeAnimationNoStyles time="1000ms">
        <GalleryWrapper>
          <ImageGallery photos={this.props.photos} />
        </GalleryWrapper>
      </FadeAnimationNoStyles>
    );
  }
}

export default index;
