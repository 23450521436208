import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const NewWeddingEntryForm = () => {
  const submit = () => {
    console.log(`submit`);
  };

  return (
    <Form>
      <Form.Group className="mb-2" controlId="formBasicTitle">
        <Form.Label>Title on Wedding Page</Form.Label>
        <Form.Control type="text" placeholder="Chris and Tara's Wedding" />
        <Form.Text className="text-muted">
          What will be shown on the page
        </Form.Text>
      </Form.Group>
      {/* 
      <Form.Group className="mb-2" controlId="formBasicUsername">
        <Form.Label>Customer Wedding Username</Form.Label>
        <Form.Control type="text" placeholder="Anderson" />
        <Form.Text className="text-muted">
          This is the username you can give the client, that they will log in with
        </Form.Text>
      </Form.Group> */}

      <Form.Group className="mb-2" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="text" placeholder="Password" />
        <Form.Text className="text-muted">
          This is the password for the client, that they will enter to view
          their photos
        </Form.Text>
      </Form.Group>
      <Button variant="primary" onClick={submit}>
        Create New Wedding
      </Button>
    </Form>
  );
};

export default NewWeddingEntryForm;
