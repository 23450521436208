import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { slideInRight, slideInLeft, slideOutLeft } from 'react-animations';

const slideInRightKeyframes = keyframes`${slideInRight}`;
const slideInLeftKeyframes = keyframes`${slideInLeft}`;
const slideOutLeftKeyframes = keyframes`${slideOutLeft}`;

export const FadeAnimation = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 700ms;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const FadeAnimationNoStyles = styled.div`
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: ${(props) => props.time || '700ms'};

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const FadeOutAnimationNoStyles = styled.div`
  opacity: 0;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: ${(props) => props.time || '4000ms'};

  @keyframes fadeInOpacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const SlideInRightAnimation = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  animation: 1s ${slideInRightKeyframes};
`;

export const SlideInRightAnimationNoStyles = styled.div`
  animation: 1s ${slideInRightKeyframes};
`;
export const SlideInLeftAnimation = styled.div`
  z-index: 200;
  animation: 500ms
    ${({ show }) => (show ? slideInLeftKeyframes : slideOutLeftKeyframes)};
`;
export const SlideOutLeftAnimation = styled.div`
  z-index: 200;
  animation: 500ms ${slideInLeftKeyframes};
`;

export const StyledLink = styled(Link)`
  display: flex;
  transition: color 0.5s;
  color: #222;
  text-decoration: none;
  font-size: 15px;
  font-weight: 700;

  &:hover {
    color: #767676;
  }
`;
