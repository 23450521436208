/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import styled from "styled-components";
import SingleGallery from "../SingleGallery";
import Spinner from "../../common/Spinner";
import { Link } from "react-router-dom";
import Gallery from "react-grid-gallery";
import { setGalleryIndex, setGalleries } from "./actions";
import { connect } from "react-redux";
import { selectGalleryIndex, selectGalleries } from "./selectors";
import * as firebase from "firebase/app";
import { FadeAnimationNoStyles } from "../../common/Styles";

const GalleryCategoriesWrapper = styled.div`
  margin: 0px auto;
  margin-bottom: 6%;
  width: 85%;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
  justify-content: center;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 500ms;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const TitleText = styled.div`
  color: white;
  font-size: 30px;
  text-decoration: none;
  text-underline-position: unset;
  color: white;
  padding: 16px 32px;
`;

const Middle = styled.div`
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
`;

const Center = styled.div`
  /* width: fit-content; */
  margin: 15px;
`;
const Box = styled.div`
  position: relative;
  align-content: center;
  justify-content: center;
  background-color: #444;
  height: 250px;
  width: 250px;
  color: #fff;
  font-size: 150%;
  margin: auto;

  /* For loading state */
  ${Middle} {
    opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  }
  img {
    opacity: ${({ isLoading }) => (isLoading ? 0.4 : 1)};
  }

  :hover {
    transform: scale(1.01);
    cursor: pointer;
  }

  &:hover img {
    opacity: 0.4;
  }

  &:hover ${Middle} {
    opacity: 1;
  }
`;

const GalleryBoxImage = styled.img`
  object-fit: cover;
  opacity: 1;
  display: block;
  width: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
`;

class index extends Component {
  state = {
    selectedCategoryIndex: null,
    loading: false,
    galleries: null,
  };

  async componentDidMount() {
    await this.fetchCloudData();
  }

  fetchCloudData = async () => {
    const db = firebase.firestore();
    const scope = this;
    const galleries = [];
    db.collection("Galleries")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          const initialData = doc.data();
          const keys = Object.keys(initialData);
          // Check if password protected
          if (keys.includes("password")) {
            // console.log(initialData[keys[0]])
            // console.log("error, pass required");
            // galleries.push({
            //   name: [doc.id],
            //   isWedding: true,
            //   data: [initialData[keys[0]]],
            // });
          } else {
            const data = keys.map((key) => initialData[key]);
            data.sort();
            // console.log(keys);
            const gallery = { name: [doc.id], data };
            galleries.push(gallery);
          }
        });

        scope.setState({
          galleries,
        });
        scope.props.setGalleries(scope.state.galleries);
      });
    this.setState({
      selectedCategoryIndex: null,
    });
  };

  fetchStorageImages = async (galleryName) => {
    let galleries = [];
    await firebase
      .storage()
      .ref(`galleries`)
      .list()
      .then((res) => {
        //Extract the gallery names
        res.prefixes.forEach((item) => {
          let gallery = { name: item.name, data: [] };
          galleries.push(gallery);
        });
        //for each gallery name...
        galleries.forEach(async (galleryObject, galleryObjectIndex) => {
          await firebase
            .storage()
            .ref(`galleries`)
            .child(galleryObject.name)
            .list()
            .then((res) => {
              let srcLinks = [];
              if (res.items.length === 0) {
                // this.setState({ storageImages: urls });
              }
              res.items.forEach(async (itemRef, index) => {
                const src = await itemRef.getDownloadURL().then();
                srcLinks.push(src);
                galleries[galleryObjectIndex].data.push(src);
                this.setState({ galleries });
              });
            })
            .catch((error) => console.log(error));
        });
      });
  };

  addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve({ height: img.height, width: img.width });
      img.onerror = reject;
      img.src = src;
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedCategoryIndex: null,
    });
  }

  async getImageDimensions(imageUrl) {
    const result = await this.addImageProcess(imageUrl);
    return result;
  }

  async asyncHandleSelectedCategory(galleryName, selectedCategoryIndex) {
    this.setState({
      loading: true,
    });
    let photos = this.state.galleries[selectedCategoryIndex].data;
    let newPhotos = await Promise.all(
      photos.map(async (photo, index) => {
        // let dimensions = await this.getImageDimensions(photos[index]);
        // const { height, width } = { ...dimensions };
        let result = {
          src: photos[index].downloadURL,
          thumbnail: photos[index].thumbnailURL,
          thumbnailHeight: photos[index].height,
          thumbnailWidth: photos[index].width,
          // width,
          // height,
        };

        // src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
        // thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
        // thumbnailWidth: 320,
        // thumbnailHeight: 212
        return result;
      })
    ).catch((error) => {
      console.log(error);
    });

    this.setState({
      loading: false,
      photos: newPhotos,
      selectedCategoryIndex,
    });
  }

  async handleSelectedCategory(galleryName, selectedCategoryIndex) {
    this.props.setGalleryIndex(selectedCategoryIndex);
    this.setState(
      {
        loading: true,
        selectedCategoryIndex: selectedCategoryIndex,
      },
      () => this.asyncHandleSelectedCategory(galleryName, selectedCategoryIndex)
    );
  }

  //   getImgSize(imgSrc) {
  //     var newImg = new Image();

  //     newImg.onload = function() {
  //       var height = newImg.height;
  //       var width = newImg.width;
  //       alert ('The image size is '+width+'*'+height);
  //     }

  //     newImg.src = imgSrc; // this must be done AFTER setting onload
  // }

  render() {
    const { selectedCategoryIndex, loading } = { ...this.state };
    const Categories =
      this.state.galleries &&
      this.state.galleries.map((gallery, i) => {
        return (
          // TODO: Wrap with onclick. Check if gallery.isWedding. If yes, show prompt for password, if no, redirect as usual (use push link)
          <Link to={`/galleries/${gallery.name}`}>
            <Box
              isLoading={
                this.props.selectGalleryIndex === i &&
                this.state.loading === true
              }
              onClick={() => this.handleSelectedCategory(gallery.name, i)}
            >
              <GalleryBoxImage
                src={gallery.data[0].thumbnailURL}
                height={250}
                width={250}
              />
              <Middle>
                <TitleText>{loading ? <Spinner /> : gallery.name}</TitleText>
              </Middle>
            </Box>
          </Link>
        );
      });

    //     // const photos = <SingleGallery photos={this.state.photos} />;
    //     const IMAGES =
    // [{
    //         src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
    //         thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
    //         thumbnailWidth: 320,
    //         thumbnailHeight: 174,
    // },
    // {
    //         src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
    //         thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
    //         thumbnailWidth: 320,
    //         thumbnailHeight: 212,
    // },

    // {
    //         src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
    //         thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
    //         thumbnailWidth: 320,
    //         thumbnailHeight: 212
    // }]
    const photos = (
      <Gallery enableImageSelection={false} images={this.state.photos} />
    );

    return selectedCategoryIndex === null ? (
      <FadeAnimationNoStyles time="1100ms">
        <GalleryCategoriesWrapper>
          <Wrapper>{Categories}</Wrapper>
        </GalleryCategoriesWrapper>
      </FadeAnimationNoStyles>
    ) : loading === true ? (
      <Spinner />
    ) : (
      <Center>{photos}</Center>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setGalleryIndex: (index) => dispatch(setGalleryIndex(index)),
  setGalleries: (galleries) => dispatch(setGalleries(galleries)),
});

const mapStateToProps = (state) => ({
  selectGalleryIndex: selectGalleryIndex(state),
  selectGalleries: selectGalleries(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
