import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";

const index = (props) => {
  // if(true) props.onHide()
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      {props.body && (
        <Modal.Body>
          <h4>Centered Modal</h4>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
            ac consectetur ac, vestibulum at eros.
          </p>
        </Modal.Body>
      )}
      <Modal.Footer>
        {!props.isWeddingPrompt && (
          <Button
            variant={"danger"}
            onClick={() => deleteAndClose(props.delete, props.onHide)}
          >
            Delete
          </Button>
        )}
        <Button
          onClick={() => {
            props.onHide();
            props.onSuccess();
          }}
        >
          {props.isWeddingPrompt ? "Submit" : "Go Back"}
        </Button>
        {/* <Button onClick={}>Go Back</Button> */}
        {props.isWeddingPrompt && "pass" === "pass" && (
          <Redirect to={props.location} />
        )}
      </Modal.Footer>
    </Modal>
  );
};

const deleteAndClose = (deleteFunction, closeFunction) => {
  deleteFunction();
  closeFunction();
};

export default index;
