import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { StyledLink as ImportedStyledLink } from '../../common/Styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faInstagram,
  faPinterestP,
} from '@fortawesome/free-brands-svg-icons';
import { setGalleryIndex } from '../GalleriesHome/actions';
import { connect } from 'react-redux';
import { slideInLeft, slideOutLeft } from 'react-animations';

const Container = styled.div`
  visibility: hidden;
  @media (max-width: 800px) {
    visibility: visible;
  }
`;

const IconContainer = styled.div`
  flex-direction: column;
  display: flex;
  font-size: 40px;
  justify-content: center;
  align-content: center;

  &:hover {
    cursor: pointer;
  }

  padding: 4px;
  background-color: rgba(128, 128, 128, 0.2);

  position: fixed;
  margin: 7px;
  z-index: 100;
  height: 43px;
  width: 50px;

  #bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  #bar2 {
    opacity: 0;
  }
  #bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -7px);
  }
`;

const Bar = styled.div`
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 3px;
  transition: 0.4s;
  opacity: 0.6;
`;

const SideBarContainer = styled.div`
  position: fixed;
  display: ${({ display }) => (display ? 'flex' : 'none')};
  /* visibility: ${({ display }) => (display ? 'visible' : 'hidden')}; */

  flex-direction: column;
  padding-top: 60px;
  justify-content: space-between;
  /* align-items: center; */

  width: 220px;
  height: 100vh;
  /* background-color: red; */
  background-color: white;
  z-index: 50;
  /* opacity: ${({ display }) => (display ? 0.9 : 0)}; */
  opacity: 0.9;

  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.69);
  animation: 500ms
    ${({ display }) =>
      display ? keyframes(slideInLeft) : keyframes(slideOutLeft)};

  /* transition: opacity 500ms;
  transition-delay: 500ms; */
`;

const LinksContainer = styled.div``;
const SocialContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 60px;
`;

const FlexItem = styled.div`
  text-align: center;
  margin: 0px 10px;
  text-decoration: none;
`;

const StyledLink = styled(ImportedStyledLink)`
  font-size: 26px;
  font-family: 'Red Hat Display', sans-serif;
`;

class index extends Component {
  state = { showSideBar: false };

  toggleSideBar = () => {
    this.setState((prevState) => ({
      showSideBar: !prevState.showSideBar,
    }));
  };
  render() {
    const navBarElements = [
      {
        route: '/about',
        title: 'About',
      },
      {
        route: '/pricing',
        title: 'Pricing',
      },
      {
        route: '/contact',
        title: 'Contact',
      },
    ];
    let NavElements = navBarElements.map((element) => (
      <FlexItem onClick={this.toggleSideBar}>
        <StyledLink to={element.route}>{element.title}</StyledLink>
      </FlexItem>
    ));
    const iconContent = Array.from({ length: 3 }, (_, i) => (
      <Bar id={this.state.showSideBar ? 'bar' + (i + 1) : ''} key={i} />
    ));
    return (
      <Container>
        <IconContainer onClick={this.toggleSideBar}>
          {iconContent}
        </IconContainer>
        <SideBarContainer display={this.state.showSideBar}>
          <LinksContainer>
            <FlexItem onClick={this.toggleSideBar}>
              <StyledLink to="/">{'Home'}</StyledLink>
            </FlexItem>
            <FlexItem onClick={this.toggleSideBar}>
              <StyledLink
                to={{
                  pathname: '/galleries',
                  state: { selectedCategoryIndex: null },
                }}
                onClick={() => this.props.setGalleryIndex(null)}
              >
                {'Galleries'}
              </StyledLink>
            </FlexItem>
            <FlexItem onClick={this.toggleSideBar}>
              <StyledLink to="/weddings">{'Weddings'}</StyledLink>
            </FlexItem>
            {NavElements}
          </LinksContainer>
          <SocialContainer>
            <StyledLink>
              <FontAwesomeIcon icon={faEnvelope} size="1x" />
            </StyledLink>
            <StyledLink>
              <FontAwesomeIcon icon={faFacebookSquare} size="1x" />
            </StyledLink>
            <StyledLink>
              <FontAwesomeIcon icon={faInstagram} size="1x" />
            </StyledLink>
            <StyledLink>
              <FontAwesomeIcon icon={faPinterestP} size="1x" />
            </StyledLink>
          </SocialContainer>
        </SideBarContainer>
      </Container>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setGalleryIndex: (index) => dispatch(setGalleryIndex(index)),
});

export default connect(
  null,
  mapDispatchToProps,
)(index);
