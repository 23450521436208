import React from 'react';
import styled from 'styled-components';
import { FadeAnimationNoStyles } from '../../common/Styles';

const Container = styled.div`
  display: flex;
  width: 80%;
  margin: 0px auto;
  margin-top: 20px;
  justify-content: center;
  text-align: center;
`;
const FadeAnimationNoStylesCascade = styled.div`
  opacity: 0;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: ${(props) => props.duration || '700ms'};
  animation-delay: ${({ delay }) => delay * 200 + 'ms' || '00ms'};
  animation-fill-mode: forwards;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const TextContainer = styled.div``;

const PortraitPackageListItems = [
  '$100',
  'Call for wedding pricing'
];

const index = (props) => {
  // const AnimatedPortraitPackageListItems = PortraitPackageListItems.map(
  //   (item, index) => (
  //     <FadeAnimationNoStylesCascade key={index} delay={index + 1}>
  //       <li>{item}</li>
  //     </FadeAnimationNoStylesCascade>
  //   ),
  // );
  return (
    <FadeAnimationNoStyles>
      <Container>
        <TextContainer>
          <h3>
            <FadeAnimationNoStylesCascade>
              <i>Pricing</i>
            </FadeAnimationNoStylesCascade>
          </h3>

          {/* <div>{AnimatedPortraitPackageListItems}</div> */}
          
          <FadeAnimationNoStylesCascade
            delay={PortraitPackageListItems.length + 2}
          >
            <br />
            $100 flat fee Includes 10 high-res digital photos
            <br />
            <br />
            Call for wedding pricing
            {/* Edited Digital Images (a set of high-resolution for print and screen
            resolution for easy upload to social media)
            <br />
            <br />
            $100 photo sessions includes 10 high-resolution digital photos
            <br />
            <br />
            <i>One week turnaround</i> */}
          </FadeAnimationNoStylesCascade>
        </TextContainer>
      </Container>
    </FadeAnimationNoStyles>
  );
};

export default index;
