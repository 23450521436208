import React, { useState } from "react";
import styled from "styled-components";
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  Nav,
  Button,
} from "react-bootstrap";
import NewWeddingEntryForm from "./NewWeddingEntryForm";
import EditExistingWeddingEntry from "./EditExistingWeddingEntry";
const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;
const WeddingAdmin = () => {
  // 0=new, 1=existing
  const [currentView, setCurrentView] = useState(NewWeddingEntryForm);

  return (
    <>
      {/* <ButtonWrapper>
        <ButtonToolbar>
          <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
            <ToggleButton
              variant="primary"
              onClick={() => setCurrentView(NewWeddingEntryForm)}
              value={1}
            >
              New Wedding Gallery
            </ToggleButton>
            <ToggleButton
              variant="primary"
              onClick={() => setCurrentView(EditExistingWeddingEntry)}
              value={2}
            >
              Edit Existing
            </ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>
      </ButtonWrapper> */}

      <Card style={{ width: '80%' }}>
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey="#first">
            <Nav.Item>
              <Nav.Link
                onClick={() => setCurrentView(NewWeddingEntryForm)}
                href="#first"
              >
                Create New Wedding
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                onClick={() => setCurrentView(EditExistingWeddingEntry)}
                href="#link"
              >
                Edit Existing Wedding
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body>
          {/* <Card.Title>Special title treatment</Card.Title>
          <Card.Text>
            With supporting text below as a natural lead-in to additional
            content.
          </Card.Text> */}

          {currentView}

          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
      </Card>
    </>
  );
};

export default WeddingAdmin;
