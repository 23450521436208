import React, { Component } from "react";
import styled from "styled-components";
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from "react-bootstrap";
import Admin from "./index";
import firebase from "firebase/app";
import "firebase/auth";
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
  IfFirebaseAuthedAnd,
} from "@react-firebase/auth";
import WeddingAdmin from "../WeddingAdmin";
import { firebaseConfig } from "../../firebase.config";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
`;

const ButtonWrapper = styled.div`
  margin: 30px;
`;

class AdminViewController extends Component {
  state = {
    showCreateNew: false,
    showWeddingView: false,
  };
  showCreateNew = (showCreateNew) => {
    this.setState({ showCreateNew, showWeddingView: false });
  };

  setWeddingView = (showWeddingView) => {
    this.setState({ showWeddingView, showCreateNew: false });
  };

  componentDidMount() {}
  signOut() {
    firebase.auth().signOut();
  }
  render() {
    return (
      <FirebaseAuthProvider
        style={{ width: "600px" }}
        {...firebaseConfig}
        firebase={firebase}
      >
        <Container>
          <FirebaseAuthConsumer>
            {({ isSignedIn, user, providerId }) => {
              return !isSignedIn ? (
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    const googleAuthProvider =
                      new firebase.auth.GoogleAuthProvider();
                    firebase.auth().signInWithPopup(googleAuthProvider);
                  }}
                >
                  Sign In
                </Button>
              ) : // (
              //   <Button
              //     variant="outline-secondary"
              //     onClick={() => {
              //       firebase.auth().signOut();
              //     }}
              //   >
              //     Sign Out
              //   </Button>
              // );
              null;
            }}
          </FirebaseAuthConsumer>
          {/* {!user && (
            // <Button
            //   onClick={() => {
            //     const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
            //     firebase.auth().signInWithPopup(googleAuthProvider);
            //   }}
            // >
            //   Sign In
            // </Button>
          )} */}
          <IfFirebaseAuthedAnd
            filter={({ providerId }) => providerId !== "anonymous"}
          >
            {({ providerId, user }) => {
              const authorizedUsers =
                process.env.REACT_APP_AUTHORIZED_USERS.split(" ");
              return authorizedUsers.includes(user.uid + "") ? (
                <Container>
                  <ButtonWrapper>
                    <ButtonToolbar>
                      <ToggleButtonGroup
                        type="radio"
                        name="options"
                        defaultValue={1}
                      >
                        <ToggleButton
                          variant="secondary"
                          onClick={() => this.showCreateNew(false)}
                          value={1}
                        >
                          Edit Existing Gallery
                        </ToggleButton>
                        <ToggleButton
                          variant="secondary"
                          onClick={() => this.showCreateNew(true)}
                          value={2}
                        >
                          Create New Gallery
                        </ToggleButton>
                        {/* <ToggleButton
                          variant="secondary"
                          onClick={() => this.setWeddingView(true)}
                          value={3}
                        >
                          Wedding Galleries
                        </ToggleButton> */}
                      </ToggleButtonGroup>
                    </ButtonToolbar>
                  </ButtonWrapper>
                  {this.state.showWeddingView ? (
                    <WeddingAdmin />
                  ) : (
                    <Admin showCreateNew={this.state.showCreateNew} />
                  )}
                </Container>
              ) : (
                <h3 style={{ margin: "40px" }} onClick={this.signOut}>
                  Unauthorized.
                </h3>
              );
            }}
          </IfFirebaseAuthedAnd>
        </Container>
      </FirebaseAuthProvider>
    );
  }
}

export default AdminViewController;
