import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  width: 500px;
  height: 30px;
  margin: 10px;
`;

const index = (props) => {
  return <StyledInput {...props} />;
};

export default index;
