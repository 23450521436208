import React from 'react';
import styled from 'styled-components';
import { FadeAnimationNoStyles } from '../../common/Styles';
import About from '../../resources/about.jpg';


const mobileBreakPoint = '700px';

const Container = styled.div`
  display: flex;
  width: 95%;
  margin: 0px auto;
  margin-bottom: 30px;

  @media (max-width: ${mobileBreakPoint}) {
    flex-direction: column;
  }
`;

const TextWrapper = styled.div`
  width: 75%;
  margin: 0px auto;
  /* background-color: #dcdcdc;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01em;
  text-transform: none;
  line-height: 1.7em;
  text-align: center;
  padding: 20px; */
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01em;
  text-transform: none;
  line-height: 1.7em;
  text-align: center;
  padding: 20px;
  @media (max-width: ${mobileBreakPoint}) {
    /* width: 100%; */
  }
`;
const TextContainer = styled.div`
  width: 50%;
  background-color: #dcdcdc;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01em;
  text-transform: none;
  line-height: 1.7em;
  text-align: center;
  padding: 20px;
  @media (max-width: ${mobileBreakPoint}) {
    width: 100%;
  }
  opacity: 0.85;
`;

const StyledParagraph = styled.p`
  margin: 30px 0px;
`;
const RonnieAndAngelaContainer = styled.div`
  width: 50%;
  @media (max-width: ${mobileBreakPoint}) {
    width: 100%;
  }
`;

const CouplePic = styled.img`
  /* width: 100%; */
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const index = (props) => {
  return (
    <FadeAnimationNoStyles>
      <Container>
        <TextContainer>
          <TextWrapper>
            <h2>Meet the Photographer</h2>
            {/* <p>
            Soon to retire after many years in the newspaper industry, I’m
            starting a new chapter in my life. I have a passion for photography
            and capturing God’s beautiful creations.
          </p>
          <p>
            Currently a Managing Editor for a newspaper and former Managing
            Editor of another newspaper and magazine, I am also a Graphic/Layout
            Designer. I’ve learned to focus on details and numerous ways to
            photograph and edit. To continue utilizing these skills and fuel my
            desire to keep on learning and improving, the picture path is
            planned. As I travel and see God’s incredible creation everywhere, I
            think, “That would make an awesome photo!”
          </p>
          <p>
            From weeds to wheat fields to water, life is imagery to behold, and
            I want to capture you in that beauty. Be adventurous and come go
            with me. I have ideas, and I’m sure you do, too. Let’s capture these
            moments together!
          </p>
          <p>
            I am Angela Barentine • wife of Ronnie Barentine • mother of four •
            grandmother of one • Navy Veteran to the great country America • a
            child of God.
          </p> */}
            <StyledParagraph>
              From weeds to wheat fields to water, life is imagery to behold,
              and I want to capture you in that beauty.{' '}
            </StyledParagraph>
            <StyledParagraph>
              My name is Angela Barentine, and I have a passion for taking
              pictures of God’s beautiful creations. As I travel and see His
              incredible works everywhere, I think, “That would make an awesome
              photo!”
            </StyledParagraph>
            <StyledParagraph>
              <b> Be adventurous, and let’s capture these moments together!</b>
            </StyledParagraph>
          </TextWrapper>
        </TextContainer>
        <RonnieAndAngelaContainer>
          <CouplePic src={About} style={{height: '100vh', width: '100%'}} />
        </RonnieAndAngelaContainer>
      </Container>
    </FadeAnimationNoStyles>
  );
};

export default index;
