import React from "react";
import firebase from "firebase";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  max-width: 200px;
  justify-content: center;
  margin: 0px auto;
  &:hover {
    cursor: pointer;
  }
`;

export default class index extends React.Component {
  state = {
    filenames: [],
    downloadURLs: [],
    isUploading: false,
    uploadProgress: 0,
    uploadSuccess: false,
    numOfFilesToUpload: 0,
    filesUploaded: 0,
    isUploadingToFirebase: false,
  };

  getPathName = () => {
    const pathName = this.props.isWedding ? "Weddings" : "Galleries";
    return pathName;
  };

  handleUploadStart = () => {
    this.setState((oldState) => ({
      uploadProgress: 0,
      uploadSuccess: false,
      numOfFilesToUpload: oldState.numOfFilesToUpload + 1,
    }));
  };

  handleProgress = (progress) =>
    this.setState({
      uploadProgress: progress,
    });

  handleUploadError = (error) => {
    this.setState({
      isUploading: false,
      // Todo: handle error
    });
    console.error(error);
  };

  getImageDimensions = async (imageUrl) => {
    const result = await this.addImageProcess(imageUrl);
    return result;
  };

  addImageProcess = (src) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve({ height: img.height, width: img.width });
      img.onerror = reject;
      img.src = src;
    });
  };

  handleUploadSuccess = async (filename) => {
    const parsedFileName = filename.replace(/[^A-Za-z0-9]+/g, "");
    const downloadURL = await firebase
      .storage()
      .ref(`${this.getPathName()}/${this.props.selectedGalleryName}`)
      .child(filename)
      .getDownloadURL();

    this.setState((oldState) => ({
      filenames: [...oldState.filenames, filename],
      downloadURLs: [...oldState.downloadURLs, downloadURL],
      uploadProgress: 100,
      isUploading: false,
      filesUploaded: oldState.filesUploaded + 1,
      // numOfFilesToUpload: oldState.numOfFilesToUpload + 1,
    }));

    //update firecloud
    const db = firebase.firestore();
    // this.state.downloadURLs.forEach((srcValue, index) => {
    // db.collection(this.getPathName())

    // GET IMAGE DIMENSIONS
    // console.log(filename.includes('thumbnail'))
    if (filename.includes("thumbnail")) {
      const parentFileName = parsedFileName.replace("thumbnail", "");
      let dimensions = await this.getImageDimensions(downloadURL);
      const { height, width } = { ...dimensions };
      db.collection("Galleries")
        .doc(this.props.selectedGalleryName)
        .set(
          {
            [parentFileName]: { thumbnailURL: downloadURL, height, width },
          },
          { merge: true }
        )
        .then(function () {
          // scope.activateAlert('success', 'Document successfully written!');
        })
        .catch(function (error) {
          // scope.activateAlert('danger', 'Error writing document!');
        });
    } else {
      db.collection("Galleries")
        .doc(this.props.selectedGalleryName)
        .set(
          {
            [parsedFileName]: { downloadURL },
          },
          { merge: true }
        )
        .then(function () {
          // scope.activateAlert('success', 'Document successfully written!');
        })
        .catch(function (error) {
          // scope.activateAlert('danger', 'Error writing document!');
        });
    }

    if (this.props.isWedding) {
      db.collection("Galleries")
        .doc(this.props.selectedGalleryName)
        .set(
          {
            password: this.props.password,
          },
          { merge: true }
        )
        .then(function () {
          // scope.activateAlert('success', 'Document successfully written!');
        })
        .catch(function (error) {
          // scope.activateAlert('danger', 'Error writing document!');
        });
    }
    // });
  };

  render() {
    return (
      <Container>
        <ButtonContainer>
          <CustomUploadButton
            accept="image/*"
            randomizeFilename={false}
            storageRef={firebase
              .storage()
              .ref(`${this.getPathName()}/${this.props.selectedGalleryName}`)}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
            multiple
            style={{
              backgroundColor: "steelblue",
              color: "white",
              padding: 10,
              borderRadius: 4,
            }}
          >
            {this.props.fromCreated
              ? "Upload Images"
              : "Upload More Images To This Gallery"}
          </CustomUploadButton>
        </ButtonContainer>
        {this.state.numOfFilesToUpload > 0 &&
          this.state.filesUploaded !== this.state.numOfFilesToUpload && (
            <h4>
              Uploading {this.state.filesUploaded}/
              {this.state.numOfFilesToUpload} files...
            </h4>
          )}

        {/* {this.state.uploadSuccess && (
          <FadeOutAnimationNoStyles>
            <h3>Gallery successfully created</h3>
          </FadeOutAnimationNoStyles>
        )} */}

        <ImageContainer>
          {this.state.downloadURLs.map((downloadURL, i) => {
            return (
              <img
                style={{ height: "50px", margin: "5px" }}
                key={i}
                src={downloadURL}
                alt={"img preview"}
              />
            );
          })}
        </ImageContainer>
        {this.state.numOfFilesToUpload !== 0 &&
          this.state.filesUploaded === this.state.numOfFilesToUpload && (
            <h4>
              Upload Complete. Please refresh the page to see your changes.
            </h4>
          )}
      </Container>
    );
  }
}
