/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import styled from "styled-components";
import SingleGallery from "../SingleGallery";
import Spinner from "../../common/Spinner";
import { Link } from "react-router-dom";
import { setGalleryIndex, setGalleries } from "./actions";
import Gallery from "react-grid-gallery";
import { connect } from "react-redux";
import { selectGalleryIndex, selectGalleries } from "./selectors";
import * as firebase from "firebase/app";
import { FadeAnimationNoStyles } from "../../common/Styles";
import PasswordPrompt from "./PasswordPrompt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";

const GalleryCategoriesWrapper = styled.div`
  margin: 0px auto;
  margin-bottom: 6%;
  width: 85%;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
  justify-content: center;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 500ms;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const TitleText = styled.div`
  color: white;
  font-size: 30px;
  text-decoration: none;
  text-underline-position: unset;
  color: white;
  padding: 16px 32px;
`;

const Middle = styled.div`
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
`;

const Center = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  max-width: 680px;
  min-midth: 50%;
  /* border: 2px solid lightgray; */
  padding: 5px;
`;

const PhotosWrapper = styled.div`
  /* width: fit-content; */
  margin: 15px;
`;

const DownloadLink = styled.div`
  /* width: fit-content; */
  /* margin: 15px; */
  color: black;
  margin: 10px;

  a {
    color: black;
  }
`;

const Box = styled.div`
  position: relative;
  align-content: center;
  justify-content: center;
  background-color: #444;
  height: 250px;
  width: 250px;
  color: #fff;
  font-size: 150%;
  margin: auto;

  /* For loading state */
  ${Middle} {
    opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  }
  img {
    opacity: ${({ isLoading }) => (isLoading ? 0.4 : 1)};
  }

  :hover {
    transform: scale(1.01);
    cursor: pointer;
  }

  &:hover img {
    opacity: 0.4;
  }

  &:hover ${Middle} {
    opacity: 1;
  }
`;

const GalleryBoxImage = styled.img`
  object-fit: cover;
  opacity: 1;
  display: block;
  width: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
`;

class index extends Component {
  state = {
    selectedCategoryIndex: null,
    galleryOnDeck: null,
    loading: false,
    galleries: null,
    showPasswordPrompt: false,
    galleryName: "",
    downloadFileId: "",
    downloadFileIdSmaller: "",
  };

  async componentDidMount() {
    await this.fetchCloudData();
    this.setState({
      downloadFileId: "",
      downloadFileIdSmaller: "",
      showPasswordPrompt: false,
    });
  }

  fetchCloudData = async () => {
    const db = firebase.firestore();
    const scope = this;
    const galleries = [];
    db.collection("Galleries")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          const initialData = doc.data();
          const keys = Object.keys(initialData);
          // Check if password protected
          if (keys.includes("password")) {
            // console.log(initialData[keys[0]])
            // console.log("error, pass required");
            const data = keys.map((key) => initialData[key]);
            galleries.push({
              name: [doc.id],
              isWedding: true,
              data: data,
            });
          }
          // else {
          //   const data = keys.map((key) => initialData[key]);
          //   data.sort();
          //   // console.log(keys);
          //   const gallery = { name: [doc.id], data };
          //   galleries.push(gallery);
          // }
        });

        scope.setState({
          galleries,
        });
        scope.props.setGalleries(scope.state.galleries);
      });
    this.setState({
      selectedCategoryIndex: null,
    });
  };

  fetchWeddingPhotos = async () => {
    this.handleSelectedCategory(this.state.galleryName);
  };

  addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve({ height: img.height, width: img.width });
      img.onerror = reject;
      img.src = src;
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedCategoryIndex: null,
    });
  }

  async getImageDimensions(imageUrl) {
    const result = await this.addImageProcess(imageUrl);
    return result;
  }

  async asyncHandleSelectedCategory(galleryName, selectedCategoryIndex) {
    this.setState({
      loading: true,
    });
    let photos = this.state.galleries[selectedCategoryIndex].data;
    let newPhotos = await Promise.all(
      photos.map(async (photo, index) => {
        let result = {
          src: photos[index].downloadURL,
          thumbnail: photos[index].thumbnailURL,
          thumbnailHeight: photos[index].height,
          thumbnailWidth: photos[index].width,
        };

        return result;
      })
    ).catch((error) => {
      console.log(error);
    });

    this.setState({
      loading: false,
      photos: newPhotos,
      selectedCategoryIndex,
    });
  }

  async handleSelectedCategory(galleryName) {
    const selectedCategoryIndex = this.state.galleryOnDeck;
    this.getDownloadId(galleryName);
    this.props.setGalleryIndex(selectedCategoryIndex);
    this.setState(
      {
        loading: true,
        selectedCategoryIndex: selectedCategoryIndex,
      },
      () => this.asyncHandleSelectedCategory(galleryName, selectedCategoryIndex)
    );
  }

  async getDownloadId(galleryName) {
    const db = firebase.firestore();
    const scope = this;
    db.collection("Galleries")
      .doc(galleryName)
      .get()
      .then(function (querySnapshot) {
        const fileId = querySnapshot.data().downloadFileId;
        const fileIdSmaller = querySnapshot.data().downloadFileIdSmaller;
        scope.setState({ downloadFileId: fileId, downloadFileIdSmaller: fileIdSmaller });
      });
  }

  render() {
    const { selectedCategoryIndex, loading } = { ...this.state };
    const Categories =
      this.state.galleries &&
      this.state.galleries.map((gallery, i) => {
        console.log(gallery);
        return (
          // TODO: Wrap with onclick. Check if gallery.isWedding. If yes, show prompt for password, if no, redirect as usual (use push link)
          <Box
            isLoading={
              this.props.selectGalleryIndex === i && this.state.loading === true
            }
            // onClick={() => this.handleSelectedCategory(gallery.name, i)}
            onClick={() =>
              this.setState({
                showPasswordPrompt: true,
                galleryName: gallery.name[0],
                galleryOnDeck: i,
              })
            }
          >
            <GalleryBoxImage
              src={gallery.data[0].thumbnailURL}
              height={250}
              width={250}
            />
            <Middle>
              <TitleText>{loading ? <Spinner /> : gallery.name}</TitleText>
            </Middle>
          </Box>
        );
      });

    const photos = (
      <Gallery enableImageSelection={false} images={this.state.photos} />
    );

    return selectedCategoryIndex === null ? (
      <FadeAnimationNoStyles time="1100ms">
        <GalleryCategoriesWrapper>
          {this.state.showPasswordPrompt && (
            <PasswordPrompt
              show
              onHide={() => this.setState({ showPasswordPrompt: false })}
              fetchWeddingPhotos={() => this.fetchWeddingPhotos()}
              galleryName={this.state.galleryName}
            />
          )}
          <Wrapper>{Categories}</Wrapper>
        </GalleryCategoriesWrapper>
      </FadeAnimationNoStyles>
    ) : loading === true ? (
      <Middle>
        <Spinner />
      </Middle>
    ) : (
      <div>
        <Center>
          <DownloadLink>
            <a
              href={`https://drive.google.com/uc?export=download&id=${this.state.downloadFileIdSmaller}&confirm=t`}
            >
              Download Photos (High Quality)
              <FontAwesomeIcon
                style={{ marginLeft: 10 }}
                icon={faCloudDownloadAlt}
                size="2x"
              />
            </a>
          </DownloadLink>
          <DownloadLink>
            <a
              href={`https://drive.google.com/uc?export=download&id=${this.state.downloadFileId}&confirm=t`}
            >
              Download Photos (Best Quality)
              <FontAwesomeIcon
                style={{ marginLeft: 10 }}
                icon={faCloudDownloadAlt}
                size="2x"
              />
            </a>
          </DownloadLink>
        </Center>
        <PhotosWrapper>{photos}</PhotosWrapper>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setGalleryIndex: (index) => dispatch(setGalleryIndex(index)),
  setGalleries: (galleries) => dispatch(setGalleries(galleries)),
});

const mapStateToProps = (state) => ({
  selectGalleryIndex: selectGalleryIndex(state),
  selectGalleries: selectGalleries(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
