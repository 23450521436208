import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import Input from "../../common/Input";
import Modal from "../../common/Modal";
import { Logo } from "../../resources/Logo.jpg";
import { setGalleryIndex, setGalleries } from "../GalleriesHome/actions";
import { connect } from "react-redux";
import {
  selectGalleryIndex,
  selectGalleries,
} from "../GalleriesHome/selectors";
import { Alert } from "react-bootstrap";
import FileUploader from "../FileUploader";
import * as firebase from "firebase/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0px auto;
  padding-bottom: 100px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 0px auto;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
`;
const EditGallerySelectionContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  align-items: baseline;
  justify-content: center;
  margin: 0px auto;
`;

const AlertContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 250px;
  opacity: ${({ showAlert }) => (showAlert ? 0.8 : 0.0)};
  transition: all 250ms ease;
`;
const ControllerLabel = styled.h4`
  text-align: center;
  margin: 0px auto;
  /* position: fixed;
  bottom: 0;
  left: 0;
  width: 250px;
  opacity: ${({ showAlert }) => (showAlert ? 0.8 : 0.0)};
  transition: all 250ms ease; */
  font-family: initial;
  margin-bottom: 30px;
`;
const SmallImage = styled.img`
  position: relative;
  left: 0px;
  top: 0px;
  height: 200px;
  width: 200px;
  object-fit: cover;
  z-index: -2;
  /* margin: 25px; */
  /* margin-right: 5px; */
`;

const Text = styled.div`
  text-align: center;
  margin: 0px auto;
`;
const DeleteContainer = styled.div`
  color: #222;
  font-size: 20px;

  &:hover {
    color: #767676;
    cursor: pointer;
  }
`;
const DisplayFileName = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: white;
  position: absolute;
  margin-top: -88px;
  margin-right: 20px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: black;
  opacity: 0.6;
  white-space: nowrap;
`;
const DeleteContainerOverImage = styled.div`
  color: #222;
  font-size: 24px;
  position: absolute;
  margin-left: 175px;
  margin-top: 80px;
  color: indianred;
  opacity: 0.8;

  &:hover {
    color: red;
    cursor: pointer;
  }
`;

const GallerySelector = styled.select`
  margin: 20px;
  width: 200px;
`;

const ImagesContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  /* width: 800px; */
  justify-content: center;
`;

const SingleImage = styled.div`
  display: flex;
  align-items: center;
  height: 200px;
  width: 200px;
  margin: 10px;
`;

class index extends Component {
  state = {
    titleInputValue: "The name of this photo gallery",
    srcValues: ["https://fakeimg.pl/440x320/282828/eae0d0/?retina=1"],
    galleries: null,
    isWedding: false,
    password: "",
    requirePassword: false,
    selectedGalleryData: null,
    selectedGalleryName: null,
    showModal: false,
    alertDetails: {
      showAlert: false,
      variant: "primary",
      message: "initial message",
    },
    storageImages: [],
    showPreviews: false,
  };

  getPathName = () => {
    const pathName = "Galleries";
    return pathName;
  };

  async componentDidMount() {
    const db = firebase.firestore();
    const scope = this;
    let galleries = [];
    db.collection(this.getPathName())
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach(function (doc) {
            const initialData = doc.data();
            const keys = Object.keys(initialData);
            const data = keys.map((key) => initialData[key]);
            const gallery = { name: [doc.id], data };
            galleries.push({ ...gallery });
          });

          const selectedGalleryData = galleries[0].data;
          const selectedGalleryName = galleries[0].name[0];
          scope.setState({
            galleries,
            selectedGalleryName,
            selectedGalleryData,
          });
          scope.fetchStorageImages(selectedGalleryName);
        }
      });
  }

  fetchStorageImages = async (galleryName) => {
    const urls = [];
    if (this.state.showPreviews) {
      await firebase
        .storage()
        .ref(`Galleries`)
        .child(galleryName)
        .list()
        .then(async (galleryRes) => {
          await firebase
            .storage()
            .ref(`Weddings`)
            .child(galleryName)
            .list()
            .then((weddingRes) => {
              const combinedRes = {
                items: [...galleryRes.items, ...weddingRes.items],
              };
              if (combinedRes.items.length === 0) {
                this.setState({ storageImages: urls });
              }
              combinedRes.items.forEach(async (itemRef) => {
                const src = await itemRef.getDownloadURL();
                const obj = {
                  src,
                  path: itemRef.fullPath,
                  filename: itemRef.name,
                };
                urls.push(obj);
                urls.sort((a, b) => (a.filename > b.filename ? 1 : -1));

                this.setState({ storageImages: urls });
              });
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  deleteStorageImage = async (path, filename, type) => {
    await firebase
      .storage()
      .ref(path)
      .delete()
      .then((res) => {
        this.fetchStorageImages(this.state.selectedGalleryName);
        this.delete(filename, type);
      });
  };

  deleteStorageGallery = async () => {
    const ref = await firebase.storage().ref(`Galleries`);
    ref
      .child(this.state.selectedGalleryName)
      .list()
      .then((res) => {
        res.items.forEach(async (itemRef) => {
          await itemRef.delete();
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  activateAlert = (variant, message) => {
    this.setState({
      alertDetails: {
        variant,
        message,
        showAlert: true,
      },
    });

    setTimeout(() => {
      this.setState({
        alertDetails: {
          variant: "success",
          message: "",
          showAlert: false,
        },
      });
    }, 3500);
  };

  changeTitleInputValue = (event) => {
    this.setState({ titleInputValue: event.target.value });
  };
  changeSrcInputValue = (event, index) => {
    let srcValues = this.state.srcValues;
    srcValues[index] = event.target.value;
    this.setState({ srcValues });
  };

  submitCreate = () => {
    const db = firebase.firestore();
    const scope = this;
    this.state.srcValues.forEach((srcValues, index) => {
      try {
        db.collection(this.getPathName())
          .doc(this.state.titleInputValue)
          .set(
            {
              [index + 1]: "https://fakeimg.pl/440x320/282828/eae0d0/?retina=1",
            },
            { merge: true }
          )
          .then(function () {
            db.storage()
              .ref(`Galleries/${this.props.selectedGalleryName}`)
              .child(Logo);
            scope.activateAlert("success", "Document successfully written!");
            scope.fetchGalleryData();
            scope.setState({
              titleInputValue: "New Gallery",
              srcValues: ["https://fakeimg.pl/440x320/282828/eae0d0/?retina=1"],
            });
          })
          .catch(function (error) {
            scope.activateAlert("danger", "Error creating document!");
          });
      } catch (error) {
        this.activateAlert("danger", "Error creating document!");
      }
    });
  };
  submitEdit = () => {
    const db = firebase.firestore();
    const scope = this;

    this.state.selectedGalleryData.forEach((srcValue, index) => {
      db.collection(this.getPathName())
        .doc(this.state.selectedGalleryName)
        .set(
          {
            [index]: srcValue || "",
          },
          { merge: true }
        )
        .then(function () {
          scope.activateAlert("success", "Document successfully written!");
        })
        .catch(function (error) {
          scope.activateAlert("danger", "Error writing document!");
        });
    });
  };

  deleteGallery = (title) => {
    const db = firebase.firestore();
    const scope = this;
    db.collection(this.getPathName())
      .doc(title)
      .delete()
      .then(function () {
        scope.activateAlert("success", "Document successfully deleted!");
        scope.deleteStorageGallery();
        scope.fetchGalleryData();
      })
      .catch(function (error) {
        console.log(error);
        scope.activateAlert("danger", "Error removing document!");
      });
  };

  delete = async (filename, type) => {
    const parsedFileName = filename.replace(/[^A-Za-z0-9]+/g, "");
    if (type === "create") {
      let srcValues = this.state.srcValues;
      let filtered = srcValues.filter(function (value, index, arr) {
        return index !== parsedFileName;
      });
      this.setState({ srcValues: filtered });
    } else {
      let db = firebase.firestore();

      var ref = await db
        .collection(this.getPathName())
        .doc(this.state.selectedGalleryName);
      ref.update({
        [parsedFileName]: firebase.firestore.FieldValue.delete(),
      });

      let srcValues = this.state.selectedGalleryData;

      let filtered = srcValues.filter(function (value, index, arr) {
        return index !== parsedFileName;
      });
      this.setState({ selectedGalleryData: filtered });
    }
  };

  addAnother = (type) => {
    if (type === "create") {
      let srcValues = this.state.srcValues;
      srcValues.push("");
      this.setState({ srcValues });
    } else {
      let selectedGalleryData = this.state.selectedGalleryData;
      selectedGalleryData.push("");
      this.setState({ selectedGalleryData });
    }
  };
  updateSelectedGalleryData = (event, index) => {
    const value = event.target.value;
    let selectedGalleryData = this.state.selectedGalleryData;
    selectedGalleryData[index] = value;
    this.setState({ selectedGalleryData });
  };

  deleteSelectedGallery = () => {
    this.deleteGallery(this.state.selectedGalleryName);
  };
  optionChanged = (event) => {
    const selectedGallery = event.target.value;
    const selectedGalleryData = this.state.galleries[selectedGallery].data;
    const selectedGalleryName = this.state.galleries[selectedGallery].name[0];
    this.setState({ selectedGalleryData, selectedGalleryName });
    this.fetchStorageImages(selectedGalleryName);
  };
  setModalShow = (showModal) => {
    this.setState({ showModal });
  };

  updateFirecloudIndexes(galleryName) {
    const db = firebase.firestore();
    const scope = this;
    db.collection(this.getPathName())
      .doc(galleryName)
      .delete()
      .then(
        scope.state.selectedGalleryData.forEach((srcValue, index) => {
          db.collection(this.getPathName())
            .doc(scope.state.selectedGalleryName)
            .set(
              {
                [index]: srcValue || "",
              },
              { merge: true }
            )
            .then(function () {
              scope.activateAlert("success", "Document successfully removed!");
            })
            .catch(function (error) {
              scope.activateAlert("danger", "Error removing document!");
            });
        })
      )
      .catch(function (error) {
        scope.activateAlert("danger", "Error deleting document!");
      });
  }
  fetchGalleryData() {
    const db = firebase.firestore();
    const scope = this;
    let galleries = [];
    db.collection(this.getPathName())
      .get()
      .then(function (querySnapshot) {
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach(function (doc) {
            const initialData = doc.data();
            const keys = Object.keys(initialData);
            const data = keys.map((key) => initialData[key]);

            const gallery = {
              name: [doc.id],
              data,
            };
            galleries.push({ ...gallery });
          });
          const selectedGalleryData = galleries[0].data;
          const selectedGalleryName = galleries[0].name[0];
          scope.setState({
            galleries,
            selectedGalleryData,
            selectedGalleryName,
          });
        } else {
          scope.setState({
            selectedGalleryData: [],
            selectedGalleryName: null,
            galleries: [],
            storageImages: [],
          });
        }
      });
  }

  render() {
    const { galleries } = { ...this.state };

    const options =
      galleries &&
      galleries.map((galleryName, index) => (
        <option value={index}>{galleryName.name}</option>
      ));

    const storageImageList = this.state.storageImages.map((imageURL, index) => (
      <SingleImage>
        <SmallImage key={index} src={imageURL.src}></SmallImage>
        <DisplayFileName>
          <span>{imageURL.filename}</span>
        </DisplayFileName>

        <DeleteContainerOverImage
          onClick={() =>
            this.deleteStorageImage(imageURL.path, imageURL.filename, "edit")
          }
        >
          <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
        </DeleteContainerOverImage>
      </SingleImage>
    ));
    return (
      <Wrapper>
        <ControllerLabel>
          {this.props.showCreateNew
            ? "Create a new Gallery"
            : "Edit Existing Galleries"}
        </ControllerLabel>
        {!this.props.showCreateNew &&
          (this.state.galleries ? (
            <Container>
              <FileUploader
                selectedGalleryName={this.state.selectedGalleryName}
              />
              <EditGallerySelectionContainer>
                Your Galleries:
                <GallerySelector onChange={(e) => this.optionChanged(e)}>
                  {options}
                </GallerySelector>
                <DeleteContainer
                  style={{ color: "red" }}
                  onClick={() => this.setModalShow(true)}
                >
                  <h6>
                    <b>
                      <i>Delete</i>
                    </b>
                    <FontAwesomeIcon
                      style={{ marginLeft: "10px", color: "red" }}
                      icon={faTrash}
                    ></FontAwesomeIcon>
                  </h6>
                </DeleteContainer>
              </EditGallerySelectionContainer>
              <Text>Images are sorted by file name</Text>

              <Form>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  {/* <Form.Check
                    onClick={(e) =>
                      this.setState({ isWedding: e.target.value })
                    }
                    type="checkbox"
                    label="Is Wedding"
                  /> */}

                  <Form.Check
                    onClick={(e) => {
                      this.setState({ showPreviews: e.target.value });
                      this.fetchStorageImages(this.state.selectedGalleryName);
                    }}
                    type="checkbox"
                    label="Enable Previews"
                  />
                </Form.Group>
              </Form>
              <ImagesContainer>{storageImageList}</ImagesContainer>
              {/* <Button
              variant="success"
              onClick={this.submitEdit}
              disabled={this.state.selectedGalleryName === null}
            >
              Save
            </Button> */}
            </Container>
          ) : (
            <Text>You have no galleries to edit</Text>
          ))}
        {this.props.showCreateNew && (
          <Container>
            Title:
            <Input
              onChange={this.changeTitleInputValue}
              value={this.state.titleInputValue}
            ></Input>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  onClick={(e) => this.setState({ isWedding: e.target.value })}
                  type="checkbox"
                  label="Is Wedding"
                />
              </Form.Group>
              {/* TODO: FIX setting to false */}
              {this.state.isWedding && (
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Password"
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </Form.Group>
              )}
            </Form>
            <FileUploader
              fromCreated
              isWedding={this.state.isWedding}
              password={this.state.password}
              selectedGalleryName={this.state.titleInputValue}
            />
          </Container>
        )}
        <Modal
          title={`Are you sure you want to delete "${this.state.selectedGalleryName}"?`}
          show={this.state.showModal}
          onHide={() => this.setModalShow(false)}
          delete={this.deleteSelectedGallery}
        />
        <AlertContainer showAlert={this.state.alertDetails.showAlert}>
          <Alert variant={this.state.alertDetails.variant}>
            {this.state.alertDetails.message}
          </Alert>
        </AlertContainer>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setGalleryIndex: (index) => dispatch(setGalleryIndex(index)),
  setGalleries: (galleries) => dispatch(setGalleries(galleries)),
});

const mapStateToProps = (state) => ({
  selectGalleryIndex: selectGalleryIndex(state),
  selectGalleries: selectGalleries(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
